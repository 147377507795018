@font-face {
  font-family: 'Gotham';
  src: url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham';
  src: url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Bold.otf');
  font-weight: bold;
}

@font-face {
  font-family: 'AtomicAge';
  src: url('https://cdn.cyberescape.livingsecurity.com/fonts/AtomicAge-Regular.ttf') format('truetype');
}
